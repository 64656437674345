import { Image } from '@finn/ui-components';
import { getCloudinaryImgUrl, getCloudinarySrcSet } from '@finn/ui-utils';
import { makeStyles, Theme } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import cn from 'classnames';
import Head from 'next/head';
import Link from 'next/link';
import React from 'react';

import { ContentItemCosmicMetadata } from '~/types/UIModules';
import { parseToHtml } from '~/utils/html';

const useStyles = makeStyles((theme: Theme) => ({
  icon: {
    width: 32,
    height: 32,
    marginBottom: theme.spacing(2),
  },
  image: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  contentWithLink: {
    ...theme.customStyles.clickableItem,
  },
}));

interface IProps {
  item: ContentItemCosmicMetadata;
  shouldPreloadImage?: boolean;
}

const ContentItem: React.FunctionComponent<IProps> = ({
  item,
  shouldPreloadImage,
}) => {
  const classes = useStyles();
  const hasLink = Boolean(item.link_href);
  let cloudinaryURL = '';

  const getComponent = () => {
    let srcSet = '';
    if (item.image?.url) {
      cloudinaryURL = getCloudinaryImgUrl(item.image?.url, { w: 360, dpr: 2 });
      srcSet = getCloudinarySrcSet(item.image?.url, [360]);
    }

    return (
      <Box
        className={cn('h-full', {
          [classes.contentWithLink]: hasLink,
        })}
      >
        {item.icon?.url && (
          <Image
            className={classes.icon}
            src={item.icon?.url}
            alt={item.title}
          />
        )}
        {item.image?.url && (
          <Image
            variant="lazy-loading"
            className={classes.image}
            src={cloudinaryURL}
            srcSet={srcSet}
            alt={item?.image_alt_text || item.title}
          />
        )}
        <h5 className={cn('global-t5-semibold mb-5', { 'm-4 mt-0 ': hasLink })}>
          {' '}
          {parseToHtml(item.title)}
        </h5>
        <p
          className={cn(
            'body-16-light line-clamp-3 overflow-hidden text-ellipsis',
            { 'm-4 mt-0 ': hasLink }
          )}
        >
          {parseToHtml(item.description)}
        </p>
      </Box>
    );
  };

  return (
    <>
      {shouldPreloadImage && (
        <Head>
          {item.icon?.url && (
            <link rel="preload" href={item.icon.url} as="image" />
          )}
          {item.image?.url && (
            <link rel="preload" href={cloudinaryURL} as="image" />
          )}
        </Head>
      )}
      {item.link_href ? (
        <Link href={item.link_href}>
          <a>{getComponent()}</a>
        </Link>
      ) : (
        getComponent()
      )}
    </>
  );
};

export default ContentItem;
