import { Slider } from '@finn/ui-modules';
import { Box, makeStyles } from '@material-ui/core';
import React from 'react';

import {
  ContentItemCosmicMetadata,
  UIBaseCosmicObject,
} from '~/types/UIModules';
import ContentItem from '~/ui-elements/ContentItem';

const useStyles = makeStyles((theme) => ({
  title: {
    marginBottom: theme.spacing(7),
  },
  itemWrapper: {
    width: 280,
    [theme.breakpoints.up('sm')]: {
      width: 360,
    },
    height: '100%',
  },
}));

interface IProps {
  title: string;
  contents: UIBaseCosmicObject<ContentItemCosmicMetadata>[];
}

const ContentSlider: React.FunctionComponent<IProps> = ({
  title,
  contents,
}) => {
  const classes = useStyles();

  return (
    <Slider title={title} titleHeadingSize={2}>
      {contents?.map((content) => (
        <Box className={classes.itemWrapper} key={content.id}>
          <ContentItem item={content.metadata} />
        </Box>
      ))}
    </Slider>
  );
};

export default ContentSlider;
